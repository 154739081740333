<template>
  <div style="font-size: 13px">
    <el-card class="information">
      <p>{{ $t("purchase.myOffer") }}</p>
    </el-card>
    <el-card>
      <p>
        The second continent after Eurasia. It covers an area of 29.2 million
        square kilometers (including 30.2 million square kilometers of islands).
        The total population is 469 million (1980). The terrain is dominated by
        terraced high plains, plateaus and Taiyuan, and there are broad tectonic
        basins in the hinterland. The longest river is the Nile. There are also
        Congo River, Niger River, Senegal River, Gambia River, Orange River,
        etc. The main type of vegetation is tropical sparse
      </p>
      <el-row class="mt img">
        <el-col :span="4">
          <img src="@/assets/Rectangle_297.png" /><br />
          <img
            src="@/assets/Frame_21.png"
            class="fl"
            style="width: 11px; height: 11px; margin-top: 3px"
          />
          <p class="fl">Daliangshan planni...</p>
          <div class="clear"></div>
        </el-col>
        <el-col :span="4">
          <img src="@/assets/Rectangle_297.png" /><br />
          <img
            src="@/assets/Frame_21.png"
            class="fl"
            style="width: 11px; height: 11px; margin-top: 3px"
          />
          <p class="fl">Daliangshan planni...</p>
          <div class="clear"></div>
        </el-col>
        <el-col :span="4">
          <img src="@/assets/Rectangle_297.png" /><br />
          <img
            src="@/assets/Frame_21.png"
            class="fl"
            style="width: 11px; height: 11px; margin-top: 3px"
          />
          <p class="fl">Daliangshan planni...</p>
          <div class="clear"></div>
        </el-col>
      </el-row>
    </el-card>

    <div v-if="data">
      <el-card class="information">
        <p>{{ $t("purchase.basic") }}</p>
      </el-card>
      <el-card>
        <el-row>
          <el-col :span="8">
            {{ $t("purchase.productName") }}: {{ data.productName }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.category") }}: {{ data.categoryName }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.purchaseType") }}: {{ data.purchaseType }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.purpose") }}: {{ data.procurementPurpose }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.quantity") }}: {{ data.quantity }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.tradeTerms") }}: {{ data.tradeTerms }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.maximumBudget") }} :
            {{ data.maximunBudget }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.detailed") }} :
            {{ data.detailDescription }}</el-col
          >
        </el-row>
        <el-row class="mt file">
          <el-col :span="8"
            ><img src="@/assets/Frame_21.png" /><span>{{
              $t("purchase.file")
            }}</span></el-col
          >
        </el-row>
      </el-card>

      <el-card class="information mt">
        <p>{{ $t("purchase.supplier") }}</p>
      </el-card>
      <el-card v-if="data.supplierRequire">
        <el-row>
          <el-col :span="8"
            >{{ $t("purchase.supplier") }} :
            {{ data.supplierRequire.businessType }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.product") }} :
            {{ data.supplierRequire.productionCert }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.business") }} :
            {{ data.supplierRequire.businessCert }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.testReport") }} :
            {{ data.supplierRequire.testReport }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.other") }} :
            {{ data.supplierRequire.otherRequire }}</el-col
          >
        </el-row>
      </el-card>

      <el-card class="information mt">
        <p>{{ $t("purchase.transportation") }}</p>
      </el-card>
      <el-card>
        <el-row>
          <el-col :span="8"
            >{{ $t("purchase.transportationMethod") }} :
            {{ data.transportMethod }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.destination") }} : {{ data.destination }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.payment") }} : {{ data.paymentMethos }}</el-col
          >
        </el-row>
      </el-card>

      <el-card class="information mt">
        <p>{{ $t("purchase.purchaser") }}</p>
      </el-card>
      <el-card>
        <el-row>
          <el-col :span="8" class="purchaser">
            <img src="@/assets/Ellipse_81.png" />
            <p>{{ $t("purchase.publisher") }} : {{ data.userName }}</p>
            <div class="clear"></div>
          </el-col>
          <el-col :span="16" class="mt"
            >{{ $t("purchase.releaseTime") }} : {{ this.createdAt }}</el-col
          >
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getData } from "../../helper/api";
const moment = require("moment");
export default {
  data() {
    return {
      data: [],
      createdAt: "",
      offer: [],
    };
  },
  created() {
    this.getData(this.$route.query.id);
  },
  methods: {
    getData(id) {
      getData("GET", "purchase", id).then((res) => {
        console.log(res);
        this.data = res.data;
        this.createdAt = moment(this.data.createdAt).format("YYYY/MM/DD HH:mm");
      });
      getData("GET", "purchaseReply", id).then((res) => {
        this.offer = res.data;
        console.log(res);
      });
    },
  },
};
</script>

<style scoped>
.img {
  font-size: 12px;
  color: #ff0a0a;
}
.img p {
  margin-left: 5px;
}
.information {
  font-size: 14px;
  font-weight: 700;
  color: #7c4919;
  background: #ffdcbc;
  border-radius: 15px 15px 0 0;
}
.file img {
  width: 11px;
  height: 11px;
}
.file span {
  color: #ff0a0a;
  margin-left: 5px;
}
.purchaser img {
  width: 45px;
  height: 45px;
  float: left;
}
.purchaser p {
  float: left;
  margin-left: 10px;
  margin-top: 15px;
}
</style>